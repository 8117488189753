.theme-green .btn-today-wrapper .btn-success, .theme-green .btn-clear-wrapper .btn-success{
  &:hover, &:active, &:focus{
    background-color: var(--mainBaseColor);
    border-color: var(--mainBaseColor);
  }
  background-color: var(--mainBaseColor);
  border-color: var(--mainBaseColor);
}
.theme-green .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active,
.theme-green .bs-datepicker-body table td span.selected
{
  background-color: var(--mainBaseColor);
  border-color: var(--mainBaseColor);
}

.theme-green .bs-datepicker-head {
  background: var(--mainBaseColor);
}
.theme-green .bs-datepicker-body table td.week span {
  color: var(--mainBaseColor);
}
