
.ng-select.my-select {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
    //background-color: #ebebeb;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #fafafa;
  }

  .ng-option {
    padding: 11px 0 10px 18px;
  }

  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 13px;
  }


  .ng-arrow-wrapper .ng-arrow {
    border: none;
    mask-image: url(./assets/img/base/clients/arrow.svg);
    width: 13px;
    height: 7px;
    border: none;
    background: #232325;
    transition: 0.3s ease-in-out;
  }

  .ng-select-container {
    padding: 11px 0 10px 8px;
    height: 39px;
    border-radius: 6px;
    border: 1px solid #d8d8d8;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }

  .ng-dropdown-panel {
    border-color: #d8d8d8;

    &.ng-select-bottom {
      border-radius: 0 0 6px 6px;
    }
  }
}

.ng-select-opened {
  > .ng-select-container .ng-arrow {
    transform: rotate(180deg);
    top: 0;
  }
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.ng-select .ng-clear-wrapper{
  display: flex;
  margin-right: 5px;
}

.ng-select .ng-clear-wrapper .ng-clear {
  display: flex;
  font-size: 15px !important;
  background: url(./assets/img/profile-edit-modal/close.svg) 100% 100% no-repeat;
  background-size: 8px 8px;
  width: 8px;
  height: 8px;
  color: transparent!important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 12px;
  left: 0;
  padding-left: 18px;
  padding-right: 50px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container{
  padding-top: 0px;
}




.ng-select .ng-select-container {
  font-size: 15px;
  color: #495057;
  border: 1px solid #c4c4c4;
  text-align-last: center;
  align-self: flex-start;
  border-radius: 8px;
  outline: none;
}



.ng-select .ng-select-container .ng-value-container .ng-input > input {
  height: 15px;
  font-size: 15px;
  padding-left: 8px;
}
